import React, { useState } from 'react';


import { Table } from '../../elements/table';
import { useDrawer } from '../../shared/drawer';
import { PageBody } from '../../elements/page-body';
import { AdministratorForm } from './components';
import { useModal } from '../../shared/modals';
import { ChangePassword } from '../change-password';

export const Administrators = () => {
    const { open: openModal, close: closeModal } = useModal();
    const [refresh, setRefresh] = useState<string | null>(null);
    const { open: openDrawer, close: closeDrawer } = useDrawer();

    const handleClose = () => {
        setTimeout(() => {
            setRefresh(Date.now().toString());
        }, 2000);

        closeDrawer();
    };

    const handleNewAction = () => {
        openDrawer(
            'common.form-title.new-admin',
            <AdministratorForm close={handleClose} />
        );
    };

    const handleChangePassword = (item: any) => {
        openModal(
            'custom',
            {
                title: 'common.form-title.change-password',
            },
            <ChangePassword close={() => {
                handleClose();
                closeModal();
            }} item={item} />
        );
    }

    return (
        <PageBody
            newAction={handleNewAction}
            title={'administrator.label.title'}
            infoText={'administrator.label.info'}
            newActionTitle={'common.label.add-new'}
        >
            <Table
                path="/administradores"
                query={''}
                refresh={refresh}
                callBeforeDrawerClosed={() => null}
                Form={AdministratorForm}
                onChangePassword={handleChangePassword}
                withPagination
                headers={[
                    {
                        key: 'first_name',
                        label: 'common.table.first-name'
                    },
                    {
                        key: 'last_name',
                        label: 'common.table.last-name'
                    },
                    {
                        key: 'email',
                        label: 'common.table.email'
                    },
                    {
                        key: 'ciudad',
                        label: 'common.table.city'
                    },
                    {
                        key: 'is_active',
                        label: 'common.table.is-active',
                        type: 'boolean'
                    }
                ]}
            />
        </PageBody>
    );
};
