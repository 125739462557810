import React from 'react';
import {Slide, ToastContainer} from 'react-toastify';
import {Outlet} from 'react-router-dom';
import login from '../../../assets/images/login.png';

export const LoginContainer = () => (
    <div>
        <div className="w-full mt-[160px]">
            <Outlet/>
        </div>
        <ToastContainer
            position="top-center"
            hideProgressBar
            autoClose={2000}
            transition={Slide}
            closeOnClick
        />
    </div>
);

