import React, { useState } from 'react';

import { Table } from '../../elements/table';
import { useDrawer } from '../../shared/drawer';
import { PageBody } from '../../elements/page-body';
import { CommercialForm, CommercialFilterForm } from './components';
import { ChangePassword } from '../change-password';
import { useModal } from '../../shared/modals';

export const Commercials = () => {
    const [query, setQuery] = useState<any>('');
    const [filter, setFilter] = useState<any>({});
    const { open: openModal, close: closeModal } = useModal();
    const { open: openDrawer, close: closeDrawer } = useDrawer();
    const [refresh, setRefresh] = useState<string | null>(null);

    const handleClose = () => {
        setTimeout(() => {
            setRefresh(Date.now().toString());
        }, 2000);

        closeDrawer();
    };

    const handleNewAction = () => {
        openDrawer(
            'common.form-title.new-commercial',
            <CommercialForm close={handleClose} />
        );
    };

    const onFilter = (values: any) => {
        setFilter(values);
        setQuery(new URLSearchParams(values));
    };

    const handleFilterAction = () => {
        openDrawer(
            'common.form-title.filter',
            <CommercialFilterForm
                item={filter}
                close={handleClose}
                onFilter={onFilter}
                doDelete={() => {
                    setQuery('');
                    setFilter({});
                    handleClose();
                }}
            />
        );
    };

    const handleChangePassword = (item: any) => {
        openModal(
            'custom',
            {
                title: 'common.form-title.change-password',
            },
            <ChangePassword close={() => {
                handleClose();
                closeModal();
            }} item={item} />
        );
    }

    return (
        <PageBody
            newAction={handleNewAction}
            title={'commercial.label.title'}
            infoText={'commercial.label.info'}
            newActionTitle={'common.label.add-new'}
            filterAction={handleFilterAction}
        >
            <Table
                path="/comerciales"
                query={query}
                refresh={refresh}
                callBeforeDrawerClosed={() => null}
                Form={CommercialForm}
                onChangePassword={handleChangePassword}
                withPagination
                headers={[
                    {
                        key: 'first_name',
                        label: 'common.table.first-name'
                    },
                    {
                        key: 'last_name',
                        label: 'common.table.last-name'
                    },
                    {
                        key: 'email',
                        label: 'common.table.email'
                    },
                    {
                        key: 'ciudad',
                        label: 'common.table.city'
                    },
                    {
                        key: 'is_active',
                        label: 'common.table.is-active',
                        type: 'boolean'
                    }
                ]}
            />
        </PageBody>
    );
};
