import React, { useState, useEffect } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore, StoreKeys } from '../store';
import { Avatar } from '../../elements/avatar';

export const NavContainer = () => {
    const { t } = useTranslation();
    const { remove, get } = useStore();
    const [profile, setProfile] = useState<any>({});

    const handelLogout = () => {
        remove();
        window.location.href = '/';
    };

    useEffect(() => {
        if (get(StoreKeys.Token)) {
            setProfile(get(StoreKeys.Profile));
        }
    }, []);

    return (
        <>
            <header className="bg-white shadow-sm lg:static lg:overflow-y-visible mb-5">
                <div className="w-full py-3 px-4 sm:px-6 lg:px-8">
                    <div className="relative flex justify-between items-center">
                        <div className="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
                            <div className="flex flex-shrink-0 items-center">
                                <a href="#">
                                    <img
                                        className="block h-[50px] w-auto"
                                        src="/logo-app.svg"
                                        alt="Samart"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="flex items-center justify-end xl:col-span-4">
                            <div className={"hidden md:flex" }>
                                <Avatar name={profile.name || profile.email} />
                            </div>
                            <a
                                onClick={handelLogout}
                                className="ml-6 cursor-pointer inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2"
                            >
                                <span>{t('common.button.logout')}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </header>

            <div className="flex flex-col flex-grow items-start w-full">
                <Outlet />
            </div>

            <ToastContainer
                position="top-center"
                hideProgressBar
                autoClose={2000}
                transition={Slide}
                closeOnClick
            />
        </>
    );
};
