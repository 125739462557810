import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../elements/input';
import { FilterFooter } from '../../../elements/filter-footer';

const schema = {};

const validations = Yup.object().shape(schema);

const initialValues = {};

export const CommercialFilterForm = (props: any) => {
    const { t } = useTranslation();

    const [data, setData] = useState<any | null>({
        ...initialValues
    });

    const onSubmit = (values: any) => {
        props.onFilter(values);
        props.close();
    };

    useEffect(() => {
        if (props.item) {
            setData({ ...data, ...props.item });
        }
    }, [props.item]);

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={data || initialValues}
            validateOnBlur={true}
            enableReinitialize={true}
            validationSchema={validations}
        >
            {({
                values,
                setFieldValue,
                handleSubmit,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    className="relative"
                    autoComplete="off"
                >
                    <Input
                        type="text"
                        name="name"
                        value={values.first_name}
                        label={t('common.label.first-name')}
                        placeholder={t('common.placeholder.first-name')}
                        onChange={(value) =>
                            setFieldValue('name', value.name)
                        }
                    />

                    <FilterFooter
                        item={props.item}
                        close={props.close}
                        doDelete={props.doDelete}
                        doSubmit={handleSubmit}
                    />
                </form>
            )}
        </Formik>
    );
};
