import { PREFIX } from '../../../constants';

export const useStore = () => {
    return {
        get: (key: string) => {
            const value = sessionStorage.getItem(`${PREFIX}${key}`);

            return value ? JSON.parse(value) : null;
        },
        del: (key: string) => sessionStorage.removeItem(`${PREFIX}${key}`),
        remove: () => sessionStorage.clear(),
        set: (key: string, value: any) =>
            sessionStorage.setItem(`${PREFIX}${key}`, JSON.stringify(value))
    };
};
