import { Route } from 'react-router-dom';

import { Bills } from '../bills';
import { MainCommercial } from './main.commercial';
import { NavContainer } from '../../shared/layouts';

export const MainCommercialRouter = () => (
    <Route element={<NavContainer />}>
        <Route path="/" element={<MainCommercial />}>
            <Route path="" element={<Bills />} />

            <Route path="*" element={<Bills />} />
        </Route>
    </Route>
);
