import { MenuNavigationItem } from '../types';

export const MENU_NAVIGATION_LINKS: MenuNavigationItem[] = [
    {
        name: 'menu.label.bills',
        href: '/',
        current: false
    },
    {
        name: 'menu.label.commercials',
        href: '/commercials',
        current: false
    },
    {
        name: 'menu.label.administrators',
        href: '/administrators',
        current: false
    },
    {
        name: 'menu.label.categories',
        href: '/categories',
        current: false
    },
    {
        name: 'menu.label.providers',
        href: '/providers',
        current: false
    },
];
