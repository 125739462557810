import {useEffect, useState} from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {Modal} from '../shared/modals';
import {NotFound} from '../shared/not-found';
import {LoginContainer} from '../shared/layouts';
import {Forgot, Login, Update} from '../pages/auth';
import {StoreKeys, useStore} from '../shared/store';
import {MainRouter} from '../pages/main/main.router';
import {ForgotSuccess} from '../pages/auth/forgot.success';
import {UpdateSuccess} from '../pages/auth/update.success';
import {MainCommercialRouter} from '../pages/main/main-commercial.router';

export const BootstrapRouter = () => {
    const {get} = useStore();
    const [logged, setLogged] = useState<boolean>(false);
    const [isCommercial, setIsCommercial] = useState<boolean>(false);

    useEffect(() => {
        if (get(StoreKeys.Token)) {
            setLogged(true);

            const profile = get(StoreKeys.Profile);

            if (profile) {
                setIsCommercial(
                    'is_comercial' in profile && profile.is_comercial
                );
            }
        }

    }, []);

    return (
        <Router>
            <Modal>
                {logged ? (
                    <Routes>
                        {isCommercial ? MainCommercialRouter() : MainRouter()}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                ) : (
                    <Routes>
                        <Route element={<LoginContainer/>}>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/forgot" element={<Forgot/>}/>
                            <Route
                                path="/forgot-success"
                                element={<ForgotSuccess/>}
                            />
                            <Route path="/recupera" element={<Login/>}/>
                            <Route
                                path="/reset-password/:token"
                                element={<Update/>}
                            />
                            <Route
                                path="/recupera-success"
                                element={<UpdateSuccess/>}
                            />
                        </Route>
                    </Routes>
                )}
            </Modal>
        </Router>
    );
};
