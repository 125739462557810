import React, {useState} from 'react';

import {useNavigate} from 'react-router-dom';

import {Table} from '../../elements/table';
import {useDrawer} from '../../shared/drawer';
import {PageBody} from '../../elements/page-body';
import {useHttpClient} from '../../shared/http-client';
import {CategoryFilterForm, CategoryForm} from './components';

export const Categories = () => {
    const navigate = useNavigate();
    const {api, state} = useHttpClient();
    const [data, setData] = useState<any[]>([]);
    const [query, setQuery] = useState<any>('');
    const [filter, setFilter] = useState<any>({});
    const {open: openDrawer, close: closeDrawer} = useDrawer();
    const [refresh, setRefresh] = useState<string | null>(null);

    const handleClose = () => {
        setTimeout(() => {
            setRefresh(Date.now().toString());
        }, 2000);

        closeDrawer();
    };

    const handleNewAction = () => {
        openDrawer(
            'common.form-title.new-category',
            <CategoryForm close={handleClose}/>
        );
    };

    const onFilter = (values: any) => {
        setFilter(values);
        setQuery(new URLSearchParams(values));
    };

    const handleFilterAction = () => {
        openDrawer(
            'common.form-title.filter',
            <CategoryFilterForm
                item={filter}
                close={handleClose}
                onFilter={onFilter}
                doDelete={() => {
                    setQuery('');
                    setFilter({});
                    handleClose();
                }}
            />
        );
    };

    return (
        <PageBody
            newAction={handleNewAction}
            title={'category.label.title'}
            infoText={'category.label.info'}
            newActionTitle={'common.label.add-new'}
            filterAction={handleFilterAction}
        >
            <Table
                path="/categorias"
                query={query}
                refresh={refresh}
                callBeforeDrawerClosed={() => null}
                Form={CategoryForm}

                withPagination
                headers={[
                    {
                        key: 'name',
                        label: 'common.table.name'
                    }, {
                        key: 'limite',
                        label: 'common.table.limite'
                    },
                ]}
            />
        </PageBody>
    );
};
