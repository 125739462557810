import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../elements/input';
import { FilterFooter } from '../../../elements/filter-footer';

const schema = {};
const validations = Yup.object().shape(schema);

const initialValues = {
};

export const ProviderFilterForm = (props: any) => {
    const { t } = useTranslation();

    const [data, setData] = useState<any | null>({
        ...initialValues
    });

    const onSubmit = (values: any) => {
        let data = values;

        if (values.proveedor?.codigo) {
            data = { ...values };
        }

        props.onFilter(data);
        props.close();
    };

    useEffect(() => {
        if (props.item) {
            setData({ ...data, ...props.item });
        }
    }, [props.item]);

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={data || initialValues}
            validateOnBlur={true}
            enableReinitialize={true}
            validationSchema={validations}
        >
            {({
                values,
                setFieldValue,
                handleSubmit,
            }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    <Input
                        type="text"
                        name="codigo"
                        value={values.codigo}
                        label={t('common.table.codigo')}
                        placeholder={t('common.placeholder.codigo')}
                        onChange={(value) =>
                            setFieldValue('codigo', value.codigo)
                        }
                    />

                    <Input
                        type="text"
                        name="name"
                        value={values.name}
                        label={t('common.table.name')}
                        placeholder={t('common.placeholder.name')}
                        onChange={(value) =>
                            setFieldValue('name', value.name)
                        }
                    />

                    <Input
                        type="text"
                        name="nif"
                        value={values.nif}
                        label={t('common.table.nif')}
                        placeholder={t('common.placeholder.nif')}
                        onChange={(value) =>
                            setFieldValue('nif', value.nif)
                        }
                    />

                    <Input
                        type="text"
                        name="ciudad"
                        value={values.ciudad}
                        label={t('common.table.ciudad')}
                        placeholder={t('common.placeholder.ciudad')}
                        onChange={(value) =>
                            setFieldValue('ciudad', value.ciudad)
                        }
                    />

                    <Input
                        type="text"
                        name="provincia"
                        value={values.provincia}
                        label={t('common.table.provincia')}
                        placeholder={t('common.placeholder.provincia')}
                        onChange={(value) =>
                            setFieldValue('provincia', value.provincia)
                        }
                    />

                    <FilterFooter
                        item={props.item}
                        close={props.close}
                        doDelete={props.doDelete}
                        doSubmit={handleSubmit}
                    />
                </form>
            )}
        </Formik>
    );
};
