import * as Yup from 'yup';

import React, {useEffect, useState} from 'react';

import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';

import { Input } from '../../../elements/input';
import { TextArea } from '../../../elements/text-area';
import { FormFooter } from '../../../elements/form-footer';
import { useHttpClient } from '../../../shared/http-client';
import {toast} from "react-toastify";

const schema = {
    name: Yup.string().required('common.error.required'),
    nif: Yup.string().required('common.error.required'),
    ciudad: Yup.string().required('common.error.required'),
    // email: Yup.string().required('common.error.required'),
    // movil: Yup.string().required('common.error.required'),
    // enlace: Yup.string().required('common.error.required'),
    // telefono: Yup.string().required('common.error.required'),
    // contacto: Yup.string().required('common.error.required'),
    // contacto_phone: Yup.string().required('common.error.required'),
    // contacto_mobile: Yup.string().required('common.error.required'),
    // contacto_email: Yup.string().required('common.error.required')
};

const validations = Yup.object().shape(schema);

const initialValues = {};

export const ProviderForm = (props: any) => {
    const {t} = useTranslation();
    const {api, state} = useHttpClient();
    // const [cities, setCities] = useState<any | null>([]);
    // const [provinces, setProvinces] = useState<any | null>([]);
    // const [province, setProvince] = useState<any | null>(null);

    const [data, setData] = useState<any | null>({
        ...initialValues
    });

    const onSubmit = (values: any | null) => {
        if (values.id) {
            api(`/proveedores/${values.id}/`, 'PATCH', values);
        } else {
            api('/proveedores/', 'POST', values);
        }

        props.close();
    };

    useEffect(() => {
        if (props.item) {
            setData({...data, ...props.item});
        }
    }, [props.item]);

    useEffect(() => {
        // api('/provincias/', 'GET');
    }, []);

    // useEffect(() => {
    //     if (province) {
    //         api(`/ciudades/?provincia=${province}`, 'GET');
    //     }
    // }, [province]);

    useEffect(() => {
        if (state.data) {
            if (state.path.includes('provincias')) {
                // setProvinces(state.data.results);
            } else if (state.path.includes('ciudades')) {
                // setCities(state.data.results);
            } else {
                props.close();
            }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    return (
        <Formik
            onSubmit={onSubmit}
            validateOnBlur={true}
            enableReinitialize={true}
            validationSchema={validations}
            initialValues={data || initialValues}
        >
            {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleSubmit,
                  handleBlur,
              }) => (
                <form
                    onSubmit={handleSubmit}
                    className="h-auto mb-[100px]"
                    autoComplete="off"
                >
                    <Input
                        type="text"
                        name="name"
                        value={values.name}
                        label={t('common.label.name')}
                        onBlurChange={handleBlur}
                        placeholder={t('common.placeholder.name')}
                        onChange={(value) => setFieldValue('name', value.name)}
                        errors={errors}
                        touched={{}}
                    />

                    <div className="flex space-x-[24px]">
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="username"
                                value={values.username}
                                onBlurChange={handleBlur}
                                label={t('common.label.username')}
                                placeholder={t('common.placeholder.username')}
                                onChange={(value) =>
                                    setFieldValue('username', value.username)
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="email"
                                value={values.email}
                                onBlurChange={handleBlur}
                                label="common.label.email"
                                placeholder="common.placeholder.email"
                                onChange={(value) =>
                                    setFieldValue('email', value.email)
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                    </div>

                    <div className="flex space-x-[24px]">
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="movil"
                                value={values.movil}
                                onBlurChange={handleBlur}
                                label={t('common.label.mobile')}
                                placeholder={t('common.placeholder.mobile')}
                                onChange={(value) =>
                                    setFieldValue('movil', value.movil)
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="telefono"
                                value={values.telefono}
                                onBlurChange={handleBlur}
                                label="common.label.phone"
                                placeholder="common.placeholder.phone"
                                onChange={(value) =>
                                    setFieldValue('telefono', value.telefono)
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                    </div>

                    <div className="flex space-x-[24px]">

                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="ciudad"
                                value={values.ciudad}
                                onBlurChange={handleBlur}
                                label={t('common.label.city')}
                                placeholder={t('common.label.city')}
                                onChange={(value) =>
                                    setFieldValue('ciudad', value.ciudad)
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="provincia"
                                value={values.provincia}
                                onBlurChange={handleBlur}
                                label={t('common.label.province')}
                                placeholder={t('common.label.province')}
                                onChange={(value) =>
                                    setFieldValue('provincia', value.provincia)
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                    </div>

                    <div className="flex space-x-[24px]">
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="ciudad"
                                value={values.ciudad}
                                onBlurChange={handleBlur}
                                label="common.label.city"
                                placeholder="common.placeholder.city"
                                onChange={(value) =>
                                    setFieldValue('ciudad', value.ciudad)
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="nif"
                                value={values.nif}
                                onBlurChange={handleBlur}
                                label="common.label.nif"
                                placeholder="common.placeholder.nif"
                                onChange={(value) =>
                                    setFieldValue('nif', value.nif)
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                    </div>

                    <Input
                        type="text"
                        name="enlace"
                        value={values.enlace}
                        label="common.label.link"
                        onBlurChange={handleBlur}
                        placeholder="common.placeholder.link"
                        onChange={(value) =>
                            setFieldValue('enlace', value.enlace)
                        }
                        errors={errors}
                        touched={{}}
                    />

                    <div className="flex space-x-[24px]">
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="contacto"
                                value={values.contacto}
                                onBlurChange={handleBlur}
                                label="common.label.contact"
                                placeholder="common.placeholder.contact"
                                onChange={(value) =>
                                    setFieldValue('contacto', value.contacto)
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="contacto_email"
                                onBlurChange={handleBlur}
                                value={values.contacto_email}
                                label="common.label.mail-contact"
                                placeholder="common.placeholder.mail-contact"
                                onChange={(value) =>
                                    setFieldValue(
                                        'contacto_email',
                                        value.contacto_email
                                    )
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                    </div>

                    <div className="flex space-x-[24px]">
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="contacto_phone"
                                onBlurChange={handleBlur}
                                value={values.contacto_phone}
                                label="common.label.phone-contact"
                                placeholder="common.placeholder.phone-contact"
                                onChange={(value) =>
                                    setFieldValue(
                                        'contacto_phone',
                                        value.contacto_phone
                                    )
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                        <div className="w-1/2">
                            <Input
                                type="text"
                                name="contacto_mobile"
                                value={values.contacto_mobile}
                                onBlurChange={handleBlur}
                                label="common.label.mobile-contact"
                                placeholder="common.placeholder.mobile-contact"
                                onChange={(value) =>
                                    setFieldValue(
                                        'contacto_mobile',
                                        value.contacto_mobile
                                    )
                                }
                                errors={errors}
                                touched={{}}
                            />
                        </div>
                    </div>

                    <FormFooter
                        item={props.item}
                        isAbsolute={false}
                        close={props.close}
                        doSubmit={handleSubmit}
                        doDelete={props.doDelete}
                    />
                </form>
            )}
        </Formik>
    );
};
