import * as Yup from 'yup';

import React, {useEffect, useState} from 'react';

import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from '../../../elements/input';
import {TextArea} from '../../../elements/text-area';
import {FormFooter} from '../../../elements/form-footer';
import {useHttpClient} from '../../../shared/http-client';

// const phoneRegExp = /^\+?(9\d{2}[1-9]\d{1})\d{4}$/;
// const mobileRegExp = /^\+?(6\d{2}|7[1-9]\d{1})\d{6}$/;
const phoneRegExp = /^[89]\d{8}$/;
const mobileRegExp = /^[67]\d{8}$/;

let schema = {
    email: Yup.string().required('common.error.required').email('common.error.email'),
    // nif: Yup.string().required('common.error.required'),
    // iban: Yup.string().trim()
    //     .matches(/[a-zA-Z]{2}[0-9]{22}$/, 'common.error.iban')
    //     .required('common.error.required'),
    // ciudad: Yup.string().required('common.error.required'),
    movil: Yup.string().matches(mobileRegExp, 'common.error.mobile').min(9, 'common.error.short').max(10, 'common.error.long').nullable(),
    telefono: Yup.string().matches(phoneRegExp, 'common.error.phone').min(9, 'common.error.short').max(10, 'common.error.long').nullable(),
    last_name: Yup.string().required('common.error.required'),
    first_name: Yup.string().required('common.error.required')
};

let validations: any;

const initialValues = {};

export const CommercialForm = (props: any) => {
    const {t} = useTranslation();
    const {api} = useHttpClient();

    const [data, setData] = useState<any | null>({
        ...initialValues
    });

    const onSubmit = (values: any | null) => {

        values.cuenta = values.iban ? values.iban.slice(4, 24) : "";
        values.username = values.email;

        if (values.id) {
            api(`/comerciales/${values.id}/`, 'PATCH', values);
        } else {
            api('/comerciales/', 'POST', values);
        }

        props.close();
    };

    useEffect(() => {
        if (props.item) {
            setData({...data, ...props.item});
        } else {
            schema = {
                ...schema,
                password: Yup.string()
                    .nullable()
                    .required('common.error.required')
                    .min(8, 'common.error.short') as any
            } as any
        }
        console.log('schema', schema)
        validations = Yup.object().shape(schema)
    }, [props.item]);

    console.log('validations', validations)

    return (
        <Formik
            onSubmit={onSubmit}
            validateOnBlur={true}
            enableReinitialize={true}
            validationSchema={validations}
            initialValues={data || initialValues}
        >
            {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
              }) => {
                return (
                    <form
                        onSubmit={handleSubmit}
                        className="relative pb-[100px] h-max"
                        autoComplete="off"
                    >
                        {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                        {/*<pre>{JSON.stringify(errors, null,   4)}</pre>*/}

                        <div className="flex space-x-[24px]">
                            <div className="w-1/2">
                                <Input
                                    type="text"
                                    name="first_name"
                                    onBlurChange={handleBlur}
                                    value={values.first_name}
                                    label={t('common.label.first-name')}
                                    placeholder={t(
                                        'common.placeholder.first-name'
                                    )}
                                    onChange={(value) =>
                                        setFieldValue(
                                            'first_name',
                                            value.first_name
                                        )
                                    }
                                    errors={errors}
                                    touched={{}}
                                />
                            </div>

                            <div className="w-1/2">
                                <Input
                                    type="text"
                                    name="last_name"
                                    value={values.last_name}
                                    onBlurChange={handleBlur}
                                    label="common.label.last-name"
                                    placeholder="common.placeholder.last-name"
                                    onChange={(value) =>
                                        setFieldValue(
                                            'last_name',
                                            value.last_name
                                        )
                                    }
                                    errors={errors}
                                    touched={{}}
                                />
                            </div>
                        </div>

                        <div className="flex space-x-[24px]">
                            <div className="w-full">
                                <Input
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    label="common.label.email"
                                    onBlurChange={handleBlur}
                                    placeholder="common.placeholder.email"
                                    onChange={(value) =>
                                        setFieldValue('email', value.email)
                                    }
                                    errors={errors}
                                    touched={{}}
                                />
                            </div>
                        </div>

                        {!values.id ?
                            <Input
                                type="password"
                                name="password"
                                value={values.password}
                                label="common.label.password"
                                onBlurChange={handleBlur}
                                placeholder="common.placeholder.password"
                                onChange={(value) =>
                                    setFieldValue('password', value.password)
                                }
                                errors={errors}
                                touched={{}}
                            />
                            : null}

                        <div className="flex space-x-[24px]">
                            <div className="w-1/2">
                                <Input
                                    type="text"
                                    name="movil"
                                    value={values.movil}
                                    label={t('common.label.mobile')}
                                    onBlurChange={handleBlur}
                                    placeholder={t('common.placeholder.mobile')}
                                    onChange={(value) =>
                                        setFieldValue('movil', value.movil)
                                    }
                                    errors={errors}
                                    touched={{}}
                                />
                            </div>
                            <div className="w-1/2">
                                <Input
                                    type="text"
                                    name="telefono"
                                    value={values.telefono}
                                    label="common.label.phone"
                                    onBlurChange={handleBlur}
                                    placeholder="common.placeholder.phone"
                                    onChange={(value) =>
                                        setFieldValue(
                                            'telefono',
                                            value.telefono
                                        )
                                    }
                                    errors={errors}
                                    touched={{}}
                                />
                            </div>
                        </div>

                        <div className="flex space-x-[24px]">
                            <div className="w-1/2">
                                <Input
                                    type="text"
                                    name="ciudad"
                                    value={values.ciudad}
                                    onBlurChange={handleBlur}
                                    label="common.label.city"
                                    placeholder="common.placeholder.city"
                                    onChange={(value) =>
                                        setFieldValue('ciudad', value.ciudad)
                                    }
                                    errors={errors}
                                    touched={{}}
                                />
                            </div>
                            <div className="w-1/2">
                                <Input
                                    type="text"
                                    name="nif"
                                    value={values.nif}
                                    onBlurChange={handleBlur}
                                    label="common.label.nif"
                                    placeholder="common.placeholder.nif"
                                    onChange={(value) =>
                                        setFieldValue('nif', value.nif)
                                    }
                                    errors={errors}
                                    touched={{}}
                                />
                            </div>
                        </div>

                        <div className="flex space-x-[24px]">
                            <div className="w-full">
                                <Input
                                    type="text"
                                    name="iban"
                                    value={values.iban}
                                    onBlurChange={handleBlur}
                                    label="common.label.iban"
                                    placeholder="common.placeholder.iban"
                                    onChange={(value) =>
                                        setFieldValue('iban', value.iban)
                                    }
                                    errors={errors}
                                    touched={{}}
                                />
                            </div>
                        </div>

                        <TextArea
                            name="observaciones"
                            label={t('common.label.observations')}
                            value={values.observaciones}
                            onBlurChange={handleBlur}
                            onChange={(value) =>
                                setFieldValue(
                                    'observaciones',
                                    value.observaciones
                                )
                            }
                            errors={errors}
                            touched={{}}
                        />

                        <Input
                            type="text"
                            name="enlace"
                            value={values.enlace}
                            onBlurChange={handleBlur}
                            label="common.label.link"
                            placeholder="common.placeholder.link"
                            onChange={(value) =>
                                setFieldValue('enlace', value.enlace)
                            }
                            errors={errors}
                            touched={{}}
                        />

                        <FormFooter
                            isAbsolute={false}
                            item={props.item}
                            close={props.close}
                            doDelete={props.doDelete}
                            doSubmit={handleSubmit}
                        />
                    </form>
                );
            }}
        </Formik>
    );
};
