import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../elements/input';
import { FormFooter } from '../../../elements/form-footer';
import { useHttpClient } from '../../../shared/http-client';

const schema = {
    name: Yup.string().required('common.error.required')
};

const validations = Yup.object().shape(schema);

const initialValues = {};

export const CategoryForm = (props: any) => {
    const { t } = useTranslation();
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>({
        ...initialValues
    });

    const onSubmit = (values: any | null) => {
        console.log(values)
        if (values.id) {
            api(`/categorias/${values.id}/`, 'PATCH', values);
        } else {
            api('/categorias/', 'POST', values);
        }
    };

    useEffect(() => {
        if (state.data) {
            props.close();
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    useEffect(() => {
        if (props.item) {
            setData({ ...data, ...props.item });
        }
    }, [props.item]);

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={data || initialValues}
            validateOnBlur={true}
            enableReinitialize={true}
            validationSchema={validations}
        >
            {({
                values,
                errors,
                setFieldValue,
                handleBlur,
                handleSubmit,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    className="relative h-full"
                    autoComplete="off"
                >
                    <Input
                        type="text"
                        name="name"
                        value={values.name}
                        onBlurChange={handleBlur}
                        label={t('common.label.name')}
                        placeholder={t('common.placeholder.name')}
                        onChange={(value) => setFieldValue('name', value.name)}
                        errors={errors}
                        touched={{}}
                    />

                    <Input
                        type="number"
                        name="limite"
                        value={values.limite || ""}
                        label={t('common.label.limite')}
                        placeholder={t('common.placeholder.limite')}
                        onChange={(value) => setFieldValue('limite', value.limite)}
                        errors={errors}
                        touched={{}}
                    />

                    <FormFooter
                        item={props.item}
                        close={props.close}
                        doDelete={props.doDelete}
                        doSubmit={handleSubmit}
                    />
                </form>
            )}
        </Formik>
    );
};
