import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const BillPrice = (props: any) => {
    const { t } = useTranslation();
    const [price, setPrice] = useState(props?.amount);

    useEffect(() => {
        if (props?.amount) {
            setPrice(
                new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(props?.amount)
            );
        } else {
            setPrice(new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR'
            }).format(0))
        }
    }, [props?.amount, props?.refresh]);

    return (
        <div className="fixe overflow-hidden rounded-lg bg-white">
            <dt>
                <p className="truncate text-sm font-thine text-gray-400 uppercase">
                    {t(props?.label)}
                </p>
            </dt>
            <dd className="flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-thine text-black">{price}</p>
            </dd>
        </div>
    );
};
