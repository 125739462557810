import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Input } from '../../elements/input';
import { useHttpClient } from '../../shared/http-client';


const schema = {
    new_password: Yup.string()
        .nullable()
        .required('common.error.required')
        .min(8, 'common.error.short')
};

const initialValues = {};
const validations = Yup.object().shape(schema);

export const ChangePassword = ({ close, item }: any) => {
    const { t } = useTranslation();
    const { api } = useHttpClient();

    const onSubmit = (values: any) => {
        api(`/users/${item.id}/update_password/`, 'PATCH', values);
        close();
    }

    return (
        <Formik
            onSubmit={onSubmit}
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validations}
        >
            {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    className="relative pb-[100px] h-max"
                    autoComplete="off"
                >
                    <Input
                        type="password"
                        name="new_password"
                        value={(values as any).new_password}
                        label={t('common.label.password')}
                        placeholder={t('common.placeholder.password')}
                        onChange={(value) => setFieldValue('new_password', value.new_password)}
                        errors={errors}
                    />

                    <button type="submit" className="flex justify-center text-white uppercase mt-[32px] items-center border h-[42px] text-sm leading-4 font-medium rounded-[4px] bg-primary w-full">
                        {t('common.button.change-password')}
                    </button>
                </form>
            )}
        </Formik>
    );
}
