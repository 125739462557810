import { useTranslation } from 'react-i18next';

export const FieldError = ({
    touched,
    errors,
    field,
    className
}: {
    touched: any;
    errors: any;
    field: string;
    className?: string;
}) => {
    const { t } = useTranslation();

    return (
        touched &&
        errors?.[field] && (
            <div
                className={`w-full text-red-600 font-regular text-[14px] text-right mt-[-21px] ${className}`}
                id="feedback"
            >
                {t(errors?.[field] as any)}
            </div>
        )
    );
};
