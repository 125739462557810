import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { classNames, noop } from '../../utils';
import { FieldError } from '../field-error/field-error';

export const Input = ({
    name,
    type,
    label,
    value,
    shower,
    disabled,
    forceWidth,
    extraHolder,
    placeholder,
    extraLabel = '',
    onChange = noop,
    onBlurChange = noop,
    errors,
    touched
}: {
    type: string;
    name: string;
    value: string | number;
    label?: string;
    shower?: boolean;
    disabled?: boolean;
    extraHolder?: string;
    extraLabel?: string;
    placeholder?: string;
    forceWidth?: number;
    errors?: any;
    touched?: any;
    onChange?: (item: { [key: string]: string | number }) => void;
    onBlurChange?: any;
}) => {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [defaultValue, setDefaultValue] = useState(value);
    const [ref, setRef] = useState<HTMLInputElement | null>(null);

    const handleChange = (e: { target: { value: string } }) => {
        if (type === 'currency') {
            const temp = e.target.value.replace(',', '.');
            const curr = temp.split('.')[0] + (temp.split('.')[1] ? '.' + temp.split('.')[1]?.slice(0, 2) : '');

            setDefaultValue(curr);
            onChange({ [name]: curr });
        } else {
            setDefaultValue(e.target.value);
            onChange({ [name]: e.target.value });
        }
    };

    useEffect(() => {
        if (isEditing) {
            ref?.focus();
        }
    }, [isEditing]);

    useEffect(() => {
        setDefaultValue(value);
    }, [value]);

    return (
        <>
            <div
                className={shower ? 'mb-0' : 'w-full  mb-[24px]'}
                style={{ width: forceWidth ? `${forceWidth}ch` : '100%' }}
            >
                {!shower && label && (
                    <label
                        htmlFor={`${type}-${name}`}
                        className="block text-sm font-normal leading-6 text-black"
                    >
                        {t(label)}{' '}{t(extraLabel)}
                    </label>
                )}
                <div
                    className={classNames(
                        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent outline-0',
                        shower
                            ? !isEditing
                                ? 'mt-1 relative'
                                : 'mt-1 relative border border-gray-dark shadow-sm'
                            : 'mt-1 relative border border-gray-dark shadow-sm'
                    )}
                    onClick={() => {
                        if (shower) {
                            setIsEditing(true);
                        }
                    }}
                >
                    <input
                        type={type === 'currency' ? 'number' : type}
                        name={name}
                        value={defaultValue || ''}
                        onBlur={onBlurChange}
                        // defaultValue={defaultValue}
                        id={`${type}-${name}`}
                        onChange={handleChange}
                        placeholder={t(placeholder || '')}
                        ref={(ref) => setRef(ref)}
                        disabled={disabled || (shower && !isEditing)}
                        className={classNames(
                            'block w-full pl-[12px] h-[40px] font-light outline-0 border-transparent focus:border-transparent',
                            extraHolder ? 'pr-12' : 'pr-[12px]',
                            disabled
                                ? 'text-gray-darker bg-gray-light'
                                : shower
                                ? ''
                                : 'bg-white text-gray-darker',
                            shower
                                ? !isEditing
                                    ? 'font-bold text-black leading-6 text-[16px] border-0 bg-transparent pl-0 pr-0 '
                                    : 'leading-6 outline-0 border-transparent bg-transparent pl-0 pr-0 focus:border-transparent not-italic font-light text-sm text-gray-darker'
                                : ''
                        )}
                    />
                    {extraHolder && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">
                                {extraHolder}
                            </span>
                        </div>
                    )}
                </div>
            </div>

            <FieldError errors={errors} touched={{}} field={name} />
        </>
    );
};
