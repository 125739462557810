import dayjs from 'dayjs';
import * as Yup from 'yup';

import React, {useEffect, useState} from 'react';

import {Formik} from 'formik';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

import {Select} from '../../../elements/select';
import {RadioGroup} from '../../../elements/radio-group';
import {useHttpClient} from '../../../shared/http-client';
import {SelectAsync} from '../../../elements/select-async';
import {FilterFooter} from '../../../elements/filter-footer';
import {Input} from "../../../elements/input";
import {DatePicker} from "../../../elements/date-picker";

const schema = {};

const validations = Yup.object().shape(schema);

const initialValues = {};

export const BillFilterForm = (props: any) => {
    const {t} = useTranslation();
    const {api, state, options} = useHttpClient();
    const [showFilter, setShowFilter] = useState<any>({});
    const [categories, setCategories] = useState<any | null>([]);
    const [commercials, setCommercials] = useState<any | null>([]);


    const [types] = useState<any>([
        {
            id: 'FACTURA',
            name: t('common.label.invoice')
        },
        {
            id: 'TICKET',
            name: t('common.label.ticket')
        },
        {
            id: 'FACTURA_TICKET',
            name: t('common.label.invoice-ticket')
        }
    ]);

    const [data, setData] = useState<any | null>({
        // ...initialValues,
        // ...props.item,
        // dates: props.item?.fecha_after ? [props.item.fecha_after, props.item.fecha_before] : [],
        comercial: props.isCommercial ? props.commercialId : props.item?.comercial ? props.item.comercial.id : '',
    });

    useEffect(() => {
        // console.log("DATA :: ", data)
    }, [data]);

    const onSubmit = (values: any) => {

        // console.log("VALORES :: ", values)

        // values.fecha_after = values.fecha_after ? dayjs(values.fecha_after).format('YYYY-MM-DD') : delete values.fecha_after;
        //
        // values.fecha_before = values.fecha_after && values.fecha_before !== 'Invalid Date'
        //     ? dayjs(values.fecha_before).format('YYYY-MM-DD')
        //     : values.fecha_after
        //         ? dayjs(values.fecha_after).format('YYYY-MM-DD')
        //         : delete values.fecha_before;

        if (values.fecha_after && values.fecha_after === "") {
            delete values.fecha_after
        }
        if (values.fecha_before && values.fecha_before === "") {
            delete values.fecha_before
        }

        if (!values.comercial) {
            delete values.comercial
            delete showFilter.comercial
        }

        if (!values.exportado) {
            delete values.exportado
            delete showFilter.exportado
        }

        if (!values.proveedor) {
            delete values.proveedor
            delete showFilter.proveedor
        }

        if (!values.categoria) {
            delete values.categoria
            delete showFilter.categoria
        }

        delete values.dates

        let data = showFilter;


        if (values.fecha_after && values.fecha_after === "") {
            data.fecha_after = dayjs().startOf('month').format('DD/MM/YYYY')
        }
        if (values.fecha_before && values.fecha_before === "") {
            data.fecha_before = dayjs().endOf('month').format('DD/MM/YYYY')
        }

        if (props.isCommercial) {
            values.exportado = false
        }
        if (!values.created_at_after && !values.fecha_after) {
            values.fecha_after = dayjs().startOf('month').format('DD/MM/YYYY')
        }
        if (!values.created_at_before && !values.fecha_before) {
            values.fecha_before = dayjs().endOf('month').format('DD/MM/YYYY')
        }

        // console.log("VALUES ONFILTER :: ", values)
        // console.log("SHOW FILTER :: ", data)

        props.onShowFilter(data);
        props.onFilter(values);

        props.close();
    };

    useEffect(() => {
        if (props.item) {
            setData({...data, ...props.item});
        }
    }, [props.item]);

    useEffect(() => {
        api('/categorias/?limit=9999999999', 'GET');

        if (!props.isCommercial) {
            api('/comerciales/?limit=9999999999', 'GET');
        }
    }, []);

    useEffect(() => {
        if (state.data) {
            if (state.path.includes('categorias')) {
                setCategories(state.data.results);
            } else if (state.path.includes('comerciales')) {
                setCommercials(state.data.results);
            }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);


    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={data}
            validateOnBlur={true}
            enableReinitialize={true}
            validationSchema={validations}
        >
            {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleSubmit,
                  handleBlur,
                  isSubmitting
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                    {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                    <div className={"font-bold"}>Fecha de creación del gasto</div>

                    <div className={"px-8 border border-gray-200 rounded-md my-4"}>
                        <DatePicker
                            value={values.created_at_after || ""}
                            onBlurChange={handleBlur}
                            name="created_at_after"
                            label={t('common.label.date-desde')}
                            onChange={(value) =>
                                setFieldValue(
                                    'created_at_after',
                                    value.created_at_after
                                )
                            }
                        />
                        <DatePicker
                            value={values.created_at_before || ""}
                            onBlurChange={handleBlur}
                            name="created_at_before"
                            label={t('common.label.date-hasta')}
                            min={values.created_at_after || ""}
                            onChange={(value) =>
                                setFieldValue(
                                    'created_at_before',
                                    value.created_at_before
                                )
                            }
                        />
                    </div>

                    <div className={"font-bold"}>Fecha de la factura/ticket</div>

                    <div className={"px-8 border border-gray-200 rounded-md my-4"}>
                        <DatePicker
                            value={values.fecha_after || ""}
                            onBlurChange={handleBlur}
                            name="fecha_after"
                            label={t('common.label.date-desde')}
                            onChange={(value) => {
                                setFieldValue(
                                    'fecha_after',
                                    value.fecha_after
                                )
                            }
                            }
                        />
                        <DatePicker
                            value={values.fecha_before || ""}
                            onBlurChange={handleBlur}
                            name="fecha_before"
                            label={t('common.label.date-hasta')}
                            min={values.fecha_after || ""}
                            onChange={(value) => {
                                setFieldValue(
                                    'fecha_before',
                                    value.fecha_before
                                )
                            }}
                        />
                    </div>

                    <Input
                        type="text"
                        onBlurChange={handleBlur}
                        name="id"
                        value={values.id}
                        label="common.label.id"
                        placeholder="common.placeholder.id"
                        onChange={(value) =>
                            setFieldValue(
                                'id',
                                value.id
                            )
                        }
                        errors={errors}
                        touched={isSubmitting}
                    />

                    <Select
                        name="tipo"
                        label={t('common.label.type')}
                        placeholder="common.label.select-value"
                        onBlurChange={handleBlur}
                        items={types}
                        value={values.tipo}
                        onChange={(value) => {
                            setShowFilter({...showFilter, tipo: types.find((item: any) => item.id === value.tipo)});
                            setFieldValue('tipo', value.tipo);
                        }}
                        errors={errors}
                        touched={{}}
                    />

                    {!props.isCommercial && (
                        <Select
                            name="comercial"
                            label={t('common.label.commercial')}
                            placeholder="common.label.select-value"
                            items={commercials}
                            labelKey="email"
                            onBlurChange={handleBlur}
                            value={values.comercial}
                            onChange={(value) => {
                                if (value.comercial) {
                                    const name = values ? commercials.find((item: any) => item.id === value.comercial) : null;
                                    setShowFilter({...showFilter, comercial: {name: value ? name.email : ''}});
                                    setFieldValue('comercial', value?.comercial || null);
                                } else {
                                    setShowFilter({...showFilter, comercial: {name: ''}});
                                    setFieldValue('comercial', null);
                                }
                            }}
                            errors={errors}
                            touched={{}}
                        />
                    )}

                    <Input
                        type="text"
                        onBlurChange={handleBlur}
                        name="numero"
                        value={values.numero}
                        label="common.label.number"
                        placeholder="common.placeholder.number"
                        onChange={(value) =>
                            setFieldValue(
                                'numero',
                                value.numero
                            )
                        }
                        errors={errors}
                        touched={isSubmitting}
                    />


                    <SelectAsync
                        name="proveedor"
                        label={t('common.label.provider')}
                        placeholder={t('common.label.write-to-search')}
                        items={(value: string) => {
                            return options(
                                `/proveedores/?limit=9999&name=${value}`
                            );
                        }}
                        attributeId="codigo"
                        extraOptionsLabel="ciudad"
                        value={values.proveedor}
                        onChange={(value: any) => {
                            setFieldValue('proveedor', value);
                            setShowFilter({...showFilter, proveedor: value});
                        }}
                    />

                    <Select
                        name="categoria"
                        label={t('common.label.category')}
                        placeholder="common.label.select-value"
                        items={categories}
                        onBlurChange={handleBlur}
                        value={values.categoria}
                        onChange={(value) => {
                            setShowFilter({
                                ...showFilter,
                                categoria: categories.find((item: any) => item.id === value.categoria)
                            });
                            setFieldValue('categoria', value.categoria);
                        }}
                        errors={errors}
                        touched={{}}
                    />

                    {props.isCommercial !== true && (
                        <RadioGroup
                            value={values.exportado}
                            name="exportado"
                            label={t('common.label.exported')}
                            items={[
                                {
                                    id: 'true',
                                    label: t('common.label.yes')

                                },
                                {
                                    id: 'false',
                                    label: t('common.label.no')
                                },
                                {
                                    id: 'all',
                                    label: t('common.label.all')
                                }
                            ]}
                            onChange={(value: any) => {
                                setShowFilter({
                                    ...showFilter, exportado: {
                                        name: value.exportado === 'all' ? t('common.label.all') : value.exportado === 'true' ? t('common.label.yes') : t('common.label.no')
                                    }
                                });
                                setFieldValue('exportado', value.exportado);
                            }}
                        />
                    )}

                    {/*<DateRangePicker values={values.dates} doChange={(values) => {*/}
                    {/*    setShowFilter({*/}
                    {/*        ...showFilter,*/}
                    {/*        fecha: values.map((date: string) => dayjs(date).format('DD/MM/YYYY'))*/}
                    {/*    });*/}
                    {/*    setFieldValue('fecha_after', dayjs(values[0]).format('YYYY-MM-DD'));*/}
                    {/*    setFieldValue('fecha_before', dayjs(values[1]).format('YYYY-MM-DD'));*/}
                    {/*}}/>*/}

                    <div className={"pb-36"}></div>

                    <FilterFooter
                        item={props.item}
                        close={props.close}
                        doDelete={props.doDelete}
                        doSubmit={handleSubmit}
                    />
                </form>
            )}
        </Formik>
    );
};
