import { isArray } from 'lodash';
import {useTranslation} from "react-i18next";

export const BillTagsFilter = ({ filter }: any) => {
    const {t} = useTranslation();
    return (
        <div className="w-full pt-[8px] pb-[16px] space-x-[6px]">
            {Object.keys(filter).map((key: string) => (
                <span key={key} className="inline-flex items-center rounded-md bg-gray px-2.5 py-0.5 text-sm">
                    <strong className="text-gray-700 capitalize">{`${t(key)}:`}&nbsp;</strong>
                    {' '}
                    <span className="text-black font-light">{isArray(filter[key]) ? `${filter[key][0]} - ${filter[key][1]}` : (filter[key]?.name)}</span>
                </span>
            ))}
        </div>
    );
}
