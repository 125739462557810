import React, { useState } from 'react';

import { ProviderFilterForm } from './components';
import { Table } from '../../elements/table';
import { useDrawer } from '../../shared/drawer';
import { PageBody } from '../../elements/page-body';

export const Providers = () => {
    const [query, setQuery] = useState<any>('');
    const [filter, setFilter] = useState<any>({});
    const [refresh, setRefresh] = useState<string | null>(null);
    const { open: openDrawer, close: closeDrawer } = useDrawer();

    const handleClose = () => {
        setRefresh(Date.now().toString());
        closeDrawer();
    };

    const onFilter = (values: any) => {
        setFilter(values);
        setQuery(new URLSearchParams(values));
    };

    const handleFilterAction = () => {
        openDrawer(
            'common.form-title.filter-provider',
            <ProviderFilterForm
                item={filter}
                close={handleClose}
                onFilter={onFilter}
                doDelete={() => {
                    setQuery('');
                    setFilter({});
                    handleClose();
                }}
            />
        );
    };

    return (
        <PageBody
            title={'provider.label.title'}
            infoText={'provider.label.info'}
            newActionTitle={'common.label.add-new'}
            filterAction={handleFilterAction}
        >
            <Table
                path="/proveedores"
                noAction
                query={query}
                refresh={refresh}
                callBeforeDrawerClosed={() => null}

                withPagination
                headers={[
                    {
                        key: 'codigo',
                        label: 'common.table.codigo'
                    },
                    {
                        key: 'name',
                        label: 'common.table.name'
                    },
                    {
                        key: 'nif',
                        label: 'common.table.nif'
                    },
                    {
                        key: 'ciudad',
                        label: 'common.table.city'
                    },
                    {
                        key: 'provincia',
                        label: 'common.table.province'
                    }
                ]}
            />
        </PageBody>
    );
};
