import {Route} from 'react-router-dom';

import {Main} from './main';
import {Bills} from '../bills';
import {Providers} from '../providers';
import {Categories} from '../categories';
import {Commercials} from '../commercials';
import {Administrators} from '../administrators';
import {MenuContainer} from '../../shared/layouts';

export const MainRouter = () => {
        return (<Route element={<MenuContainer/>}>
            <Route path="/" element={<Main/>}>
                <Route path="" element={<Bills/>}/>
                <Route path="providers" element={<Providers/>}/>
                <Route path="categories" element={<Categories/>}/>
                <Route path="commercials" element={<Commercials/>}/>
                <Route path="administrators" element={<Administrators/>}/>

                <Route path="*" element={<Bills/>}/>
            </Route>
        </Route>)
    }
;
