import {useTranslation} from 'react-i18next';
import {classNames, noop} from '../../utils';
import AsyncSelect from 'react-select/async';
import {FieldError} from "../field-error/field-error";

export const SelectAsync = ({
                                name,
                                label,
                                disabled,
                                value = {},
                                items = [],
                                placeholder,
                                onChange = noop,
                                noMargin = false,
                                attributeId = 'id',
                                optionsLabel = 'name',
                                extraOptionsLabel = '',
                                errors,
                                touched,

                            }: {
    name: string;
    label?: string;
    optionsLabel?: string;
    attributeId?: string;
    noMargin?: boolean;
    disabled?: boolean;
    extraOptionsLabel?: string;
    placeholder?: string;
    items: any;
    value?: { id?: number | string; name?: string } | any;
    onChange: (item: { [key: string]: number | string } | any) => void;
    errors?: any;
    touched?: any;
}) => {
    const {t} = useTranslation();

    return (
        <>
            <div className={classNames('w-full', noMargin ? 'mb-0' : 'mb-[24px]')}>
                <div className="block text-sm font-medium text-gray-700 mb-1">
                    {t(label || '')}
                </div>
                <AsyncSelect
                    name={name}
                    placeholder={placeholder}
                    isClearable
                    isDisabled={disabled}
                    getOptionLabel={(e: any) =>
                        e[optionsLabel] ? extraOptionsLabel && e[extraOptionsLabel] ?
                            `${e[optionsLabel]} - ${e[extraOptionsLabel]}` :
                            e[optionsLabel] : ''
                    }
                    getOptionValue={(e: any) => e[attributeId]}
                    loadOptions={items}
                    value={value}
                    onChange={onChange}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0
                    })}
                />
            </div>

            <FieldError errors={errors} touched={{}} field={name}/>
        </>
    )
        ;
};
