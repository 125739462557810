import React, {useEffect, useState} from 'react';

import dayjs from 'dayjs';

import {toast} from 'react-toastify';
import {Table} from '../../elements/table';
import {useDrawer} from '../../shared/drawer';
import {PageBody} from '../../elements/page-body';
import {useHttpClient} from '../../shared/http-client';
import {StoreKeys, useStore} from '../../shared/store';
import {BillFilterForm, BillForm, BillPrice, BillTagsFilter} from './components';
import {useTranslation} from 'react-i18next';

export const Bills = () => {
    const {get, set, remove} = useStore();
    const {api, state} = useHttpClient();
    const [query, setQuery] = useState<any>('');
    const {t} = useTranslation();
    const [profile, setProfile] = useState<any>({});

    const [filter, setFilter] = useState<any>({
        created_at_after: dayjs().startOf('month').format('YYYY-MM-DD'),
        created_at_before: dayjs().endOf('month').format('YYYY-MM-DD'),
        // fecha_after: dayjs().startOf('month').format('YYYY-MM-DD'),
        // fecha_before: dayjs().endOf('month').format('YYYY-MM-DD'),
    });

    const [showFilter, setShowFilter] = useState<any>({
        fecha_creacion: [
            dayjs().startOf('month').format('DD/MM/YYYY'),
            dayjs().endOf('month').format('DD/MM/YYYY')
        ]
    });

    const [isReady, setIsReady] = useState<boolean>(false);
    const [data, setData] = useState<any | null>(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [exportLoading, setExportLoading] = useState(false);
    const {open: openDrawer, close: closeDrawer} = useDrawer();
    const [refreshDate, setRefreshDate] = useState(0);
    const [isCommercial, setIsCommercial] = useState<boolean>(false);
    const [totalCategoryAmount, setTotalCategoryAmount] = useState(0);

    const handleClose = () => {
        setTimeout(() => {
            closeDrawer();
        }, 200);
    };

    const handleCloseNewAction = () => {
        setRefreshDate(new Date().getTime());
        setFilter({...filter});
        setQuery(new URLSearchParams(filter));

        api('/gastos/?limit=9999999999&' + new URLSearchParams(filter).toString());

        setTimeout(() => {
            closeDrawer();
        }, 200);
    };

    const handleCancelAction = () => {
        setRefreshDate(new Date().getTime());
        setTimeout(() => {
            closeDrawer();
        }, 200);
    };

    const handleNewAction = () => {
        openDrawer(
            'common.form-title.new-bill',
            <BillForm close={handleCloseNewAction} cancel={handleCancelAction} isCommercial={isCommercial}
                      commercialId={profile.id}/>,
            true,
            'xl'
        );
    };

    const onShowFilter = (values: any) => {
        setShowFilter({...showFilter, ...values});
    }

    const onFilter = (values: any) => {
        if (isCommercial) {
            values.exportado = false;
        }
        set("filtroBills", values);
        set("/bills-offset", 0);
        setFilter(values);

        if ("created_at_after" in values) {
            setShowFilter({
                ...showFilter,
                fecha_creacion: [
                    dayjs(values.created_at_after).format("DD/MM/YYYY"),
                    dayjs(values.created_at_before).format("DD/MM/YYYY")
                ]
            })
        }

        // setTimeout(() => {
        //     window.location.reload()
        handleClose();
        // }, 200);
    };

    const handleFilterAction = () => {
        openDrawer(
            'common.form-title.filter',
            <BillFilterForm
                item={filter}
                isCommercial={isCommercial}
                close={handleClose}
                onFilter={onFilter}
                commercialId={profile.id}
                doDelete={() => {
                    console.log("RESET DE FILTROS")
                    set("/bills-offset", 0);

                    setFilter({
                        // fecha_after: dayjs().startOf('month').format('YYYY-MM-DD'),
                        // fecha_before: dayjs().endOf('month').format('YYYY-MM-DD'),
                        created_at_after: dayjs().startOf('month').format('YYYY-MM-DD'),
                        created_at_before: dayjs().endOf('month').format('YYYY-MM-DD'),
                        exportado: isCommercial ? false : null,
                        comercial: isCommercial ? profile.id : ''
                    });

                    set("filtroBills", {
                        // fecha_after: dayjs().startOf('month').format('YYYY-MM-DD'),
                        // fecha_before: dayjs().endOf('month').format('YYYY-MM-DD'),
                        created_at_after: dayjs().startOf('month').format('YYYY-MM-DD'),
                        created_at_before: dayjs().endOf('month').format('YYYY-MM-DD'),
                        exportado: isCommercial ? false : null,
                        comercial: isCommercial ? profile.id : ''
                    });


                    setShowFilter({
                        fecha: [
                            dayjs().startOf('month').format('DD/MM/YYYY'),
                            dayjs().endOf('month').format('DD/MM/YYYY')
                        ]
                    });


                    closeDrawer();
                    window.location.reload()
                }}
                onShowFilter={onShowFilter}
            />
        );
    };

    const handleExportAction = () => {
        const theFilter = ({...filter});
        const theQuery = (new URLSearchParams(theFilter));

        if (!isCommercial) {
            api('/gastos/export_gastos_xls/?' + theQuery, 'GET', null, false, true);
        } else {
            api('/gastos/export/?' + theQuery, 'GET', null, false, true);
        }
    };

    const handleExportCategoriesAction = () => {
        const theFilter = ({...filter});
        const theQuery = (new URLSearchParams(theFilter));

        if (!isCommercial) {
            api('/gastos/export_categorias/?' + theQuery, 'GET', null, false, true);
        } else {
            api('/gastos/export_categorias/?' + theQuery, 'GET', null, false, true);
        }
    };

    const refreshTotalAmount = (data: any) => {
        let total: number = 0;
        let totalCategory: any = {};

        data.forEach((item: any) => {
            total += +item.importe + +item.iva || 0;
            total += +item.importe_2 || 0 + +item.iva_2 || 0;
            total += +item.importe_3 || 0 + +item.iva_3 || 0;
            total += +item.importe_4 || 0 + +item.iva_4 || 0;
            total += -item.bonificacion || 0;

            if (totalCategory[item.categoria.name]) {
                totalCategory[item.categoria.name] += +item.importe + +item.iva || 0
                totalCategory[item.categoria.name] += +item.importe_2 || 0 + +item.iva_2 || 0;
                totalCategory[item.categoria.name] += +item.importe_3 || 0 + +item.iva_3 || 0;
                totalCategory[item.categoria.name] += +item.importe_4 || 0 + +item.iva_4 || 0;

                totalCategory[item.categoria.name] -= +item.bonificacion || 0;

            } else {
                let curr = +item.importe + +item.iva || 0;

                curr += (+item.importe_2 || 0 + +item.iva_2 || 0);
                curr += (+item.importe_3 || 0 + +item.iva_3 || 0);
                curr += (+item.importe_4 || 0 + +item.iva_4 || 0);

                totalCategory[item.categoria.name] = curr - +item.bonificacion || 0;
            }
        });

        setTotalAmount(total);
        setTotalCategoryAmount(totalCategory);
    }

    useEffect(() => {
        if (get(StoreKeys.Token)) {
            const profile = get(StoreKeys.Profile);

            setProfile(profile);

            if (profile) {
                const isCommercialTemp = 'is_comercial' in profile && profile.is_comercial;

                setIsReady(true);

                setIsCommercial(isCommercialTemp);

                setFilter({
                    ...filter,
                    exportado: isCommercialTemp ? false : null,
                    comercial: isCommercialTemp ? profile.id : ''
                });
            }
        }
    }, [isCommercial]);

    useEffect(() => {
        if (isReady) {
            const filtrosGuardados = get("filtroBills");

            // console.log("filtros guardados", filtrosGuardados)
            // console.log("filtros pedidos", filter)

            const tempFilter = {
                ...filtrosGuardados,
                ...filter,
                proveedor: filter?.proveedor?.codigo || '',
                exportado: isCommercial ? false : null,
                comercial: isCommercial ? profile.id : filter?.comercial || ''
            };
            // console.log("filtros finales", tempFilter)
            setRefreshDate(new Date().getTime());
            setQuery(new URLSearchParams(tempFilter));

            api('/gastos/?limit=9999999999&' + new URLSearchParams(tempFilter).toString());

            setTimeout(() => {
                setRefreshDate(0);
            }, 500);
        }
    }, [filter]);

    useEffect(() => {
        if (state.data) {
            if (state.path.split('?')[0].includes('export_categorias')) {
                setExportLoading(state.data.isLoading);

                const url = window.URL.createObjectURL(new Blob([state.data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', `gastos_${Date.now()}.xlsx`);

                document.body.appendChild(link);

                link.click();

                setTimeout(() => {
                    window.location.reload();
                }, 500);

            } else if (state.path.split('?')[0].includes('export')) {
                setExportLoading(state.data.isLoading);

                const url = window.URL.createObjectURL(new Blob([state.data]));
                const link = document.createElement('a');

                link.href = url;
                if (isCommercial) {
                    link.setAttribute('download', `gastos_${Date.now()}.xlsx`);
                } else {
                    link.setAttribute('download', `gastos_${Date.now()}.xlsx`);
                }

                document.body.appendChild(link);

                link.click();

                setTimeout(() => {
                    window.location.reload();
                }, 500);

            } else if (state.data.results) {
                setData(state.data.results);
                refreshTotalAmount(state.data.results);
            }
        } else if (state.error) {
            toast.error(t('common.error.not-data-export'));
        }
    }, [state]);

    useEffect(() => {
        const filtrosGuardados = get("filtroBills") || {};
        if (Object.keys(filtrosGuardados).length > 0) {
            setFilter({
                ...filtrosGuardados,
                exportado: isCommercial ? false : null,
                comercial: isCommercial ? profile.id : ''
            });
        } else {
            setFilter({
                // fecha_after: dayjs().startOf('month').format('YYYY-MM-DD'),
                // fecha_before: dayjs().endOf('month').format('YYYY-MM-DD'),
                created_at_after: dayjs().startOf('month').format('YYYY-MM-DD'),
                created_at_before: dayjs().endOf('month').format('YYYY-MM-DD'),
                exportado: isCommercial ? false : null,
                comercial: isCommercial ? profile.id : ''
            });
        }

        if (get("/bills-offset") === null) {
            set("/bills-offset", 0);
        }

    }, []);

    return (
        <PageBody
            exportAction={handleExportAction}
            exportActionTitle="common.label.export"
            secondaryAction={handleExportCategoriesAction}
            secondaryActionTitle="common.label.export-categories"
            exportStatus={exportLoading}
            newAction={handleNewAction}
            title={'bill.label.title'}
            infoText={'bill.label.info'}
            newActionTitle={'common.label.add-new'}
            filterAction={handleFilterAction}
        >
            <BillTagsFilter filter={showFilter}/>

            <div className="flex flex-col md:flex-row md:justify-between">
                <div className="flex flex-col md:flex-row md:space-x-10">
                    {Object.keys(totalCategoryAmount).map((key: string) => (
                        <BillPrice
                            key={key}
                            label={key}
                            amount={(totalCategoryAmount as any)[key]}
                        />
                    ))}
                </div>

                <BillPrice
                    label="common.label.total"
                    amount={totalAmount}
                    refresh={refreshDate}
                />
            </div>

            <Table
                path="/gastos"
                storePath="/bills"
                refreshDate={refreshDate}
                refresh={refreshDate}
                query={query.toString()}
                callBeforeDrawerClosed={handleCloseNewAction}
                Form={(props: any) => <BillForm {...props}
                                                cancel={handleCancelAction}
                                                close={handleCloseNewAction}
                                                isCommercial={isCommercial}
                                                commercialId={profile.id}/>}
                refreshCustomData={refreshTotalAmount}
                withPagination
                headers={[
                    {
                        key: 'id',
                        label: 'common.table.id'
                    },
                    {
                        key: 'comercial',
                        label: 'common.table.commercial'
                    },
                    {
                        key: 'created_at',
                        label: 'common.table.date-created',
                        type: 'date'
                    },
                    {
                        key: 'fecha',
                        label: 'common.label.date-ticket',
                        type: 'date'
                    },
                    {
                        key: 'numero',
                        label: 'common.table.number'
                    },
                    {
                        key: 'proveedor',
                        label: 'common.table.provider'
                    },
                    {
                        key: 'categoria',
                        label: 'common.table.category'
                    },
                    {
                        key: 'importe',
                        label: 'common.table.base',
                        type: 'currency'
                    },
                    {
                        key: 'iva',
                        label: 'common.table.iva',
                        type: 'currency'
                    },
                    {
                        key: 'bonificacion',
                        label: 'common.table.bonification',
                        type: 'currency'
                    },
                    {
                        key: 'total_importe',
                        label: 'common.table.total',
                        type: 'currency'
                    },
                    {
                        key: 'exportado',
                        label: 'clientes.form-label.exportado',
                        type: 'boolean'
                    },
                ]}
            />
        </PageBody>
    );
};
