import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../elements/input';
import { Toggle } from '../../../elements/toggle';
import { Select } from '../../../elements/select';
import { FormFooter } from '../../../elements/form-footer';
import { useHttpClient } from '../../../shared/http-client';
import { FieldError } from '../../../elements/field-error/field-error';

const schema = {
    name: Yup.string().required('common.error.required')
};

const validations = Yup.object().shape(schema);

const initialValues = {
    name: '',
    activo: true
};

export const AdministratorFilterForm = (props: any) => {
    const { api, state } = useHttpClient();
    const [data, setData] = useState<any | null>({
        ...initialValues
    });

    const onSubmit = () => {
        console.log('submit', data);
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={data || initialValues}
            validateOnBlur={true}
            enableReinitialize={true}
            validationSchema={validations}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting
            }) => <form onSubmit={handleSubmit} autoComplete="off"></form>}
        </Formik>
    );
};
