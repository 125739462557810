import React, { useEffect, useState } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import {Link, Outlet, useLocation} from 'react-router-dom';
import { LogoutIcon } from '@heroicons/react/outline';
import { classNames } from '../../../utils';
import { MENU_NAVIGATION_LINKS } from './constants';
import { StoreKeys, useStore } from '../store';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../elements/avatar';

export const MenuContainer = () => {
    const { t } = useTranslation();
    const { remove, get } = useStore();
    const location = useLocation();
    const [profile, setProfile] = useState<any>({});

    const handelLogout = () => {
        remove();
        window.location.href = '/';
    };

    useEffect(() => {
        if (get(StoreKeys.Token)) {
            setProfile(get(StoreKeys.Profile));
        }
    }, []);

    // @ts-ignore
    return (
        <>
            <div className="flex flex-row h-full">
                <div className="fixed inset-y-0 bg-white">
                    <div className="relative flex flex-col w-72 h-screen px-4">
                        <div className="flex-1 h-0  overflow-y-auto">
                            <div className="flex items-center mb-8">
                                <div
                                    className={'text-4xl font-bold  text-white'}
                                >
                                    <img
                                        className=""
                                        src="/logo-app.svg"
                                        alt="Workflow"
                                    />
                                </div>
                            </div>
                            <nav className="mt-[50px] space-y-3">
                                {MENU_NAVIGATION_LINKS.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            location.pathname ===
                                                item.href
                                                ? 'bg-primary text-white  rounded-md'
                                                : 'text-gray-900 hover:text-gray-900 hover:bg-gray-300 hover:rounded-md',
                                            'group flex items-center px-5 py-4 text-base text-[18px] font-regular'
                                        )}
                                    >
                                        {t(item.name)}{' '}
                                    </Link>
                                ))}
                            </nav>
                        </div>
                        <div className="flex-shrink-0 flex flex-col border-t bg-gray-100 mb-4 border-gray-200 rounded-md p-4">
                            <div className="flex-shrink-0 group block">
                                <div className="flex items-center">
                                    {profile && (
                                        <>
                                            <Avatar
                                                name={
                                                    profile.name ||
                                                    profile.email
                                                }
                                            />
                                            <div className="ml-3">
                                                <p className="text-base font-medium text-black">
                                                    {profile.name ||
                                                        profile.email}
                                                </p>
                                                <p className="text-base font-thin text-gray-500">
                                                    {profile.name &&
                                                        profile.email}
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="flex-shrink-0 text-gray-500 flex pt-10">
                                <button
                                    onClick={handelLogout}
                                    className="flex space-x-1 items-center flex-shrink-0"
                                >
                                    <LogoutIcon className="h-5 w-5 mx-2" />
                                    <span>{t('common.button.logout')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex ml-72 flex-col flex-1 mt-4">
                    <Outlet />
                </div>
            </div>

            <ToastContainer
                position="top-center"
                hideProgressBar
                autoClose={2000}
                transition={Slide}
                closeOnClick
            />
        </>
    );
};
