import * as Yup from 'yup';

import React, {useEffect, useState} from 'react';

import {CogIcon, EyeIcon, TrashIcon,} from '@heroicons/react/outline';

import {Formik} from 'formik';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

import {classNames} from '../../../utils';
import {Input} from '../../../elements/input';
import {Select} from '../../../elements/select';
import {useModal} from '../../../shared/modals';
import {BillImageViewer} from './bill-image-viewer';
import {FormFooter} from '../../../elements/form-footer';
import {DatePicker} from '../../../elements/date-picker';
import {useHttpClient} from '../../../shared/http-client';
import {SelectAsync} from '../../../elements/select-async';
import {FileInputElement} from '../../../elements/file-input';
import {FieldError} from "../../../elements/field-error/field-error";
import {Toggle} from "../../../elements/toggle";
import dayjs from "dayjs";

const schema = {
    tipo: Yup.string().required('common.error.required'),
    fecha: Yup.string()
        .test("futura", "No puede ser una fecha futura", (val) => {
            console.log("LA FECHA :: ", val)
            return !dayjs(val).isAfter(dayjs())
        })
        .required('common.error.required'),
    numero: Yup.string().required('common.error.required'),
    importe: Yup.number().required('common.error.required').min(0, "common.error.positive"),
    iva: Yup.number().required('common.error.required').min(0, "common.error.positive"),
    importe_2: Yup.number().nullable().min(0, "common.error.positive"),
    iva_2: Yup.number().nullable().min(0, "common.error.positive"),
    importe_3: Yup.number().nullable().min(0, "common.error.positive"),
    iva_3: Yup.number().nullable().min(0, "common.error.positive"),
    importe_4: Yup.number().nullable().min(0, "common.error.positive"),
    iva_4: Yup.number().nullable().min(0, "common.error.positive"),
    bonificacion: Yup.string().required('common.error.required'),
    categoria: Yup.string().required('common.error.required'),
    proveedor: Yup.object().nullable(),
    comercial: Yup.string().required('common.error.required'),
};

const validations = Yup.object().shape(schema);


const schemaTICKET = {
    tipo: Yup.string().required('common.error.required'),
    fecha: Yup.string()
        .test("futura", "no puede ser una fecha futura", (val) => {
            console.log("LA FECHA :: ", val)
            return !dayjs(val).isAfter(dayjs())
        })
        .required('common.error.required'),
    numero: Yup.string().required('common.error.required'),
    importe: Yup.number().required('common.error.required').min(0, "common.error.positive"),
    iva: Yup.number().required('common.error.required').min(0, "common.error.positive"),
    categoria: Yup.string().required('common.error.required'),
    comercial: Yup.string().required('common.error.required'),
};

const validationsTICKET = Yup.object().shape(schemaTICKET);

const initialValues = {
    bonificacion: 0,
    iva: 0,
};

export const BillForm = (props: any) => {
    const {open} = useModal();
    const {t} = useTranslation();
    const [base, setBase] = useState<any>([1, 2, 3, 4]);
    const [images, setImages] = useState<any>([]);
    const {api, state, options} = useHttpClient();
    const [showTimeout, setShowTimeout] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState<any>(false);
    const [categories, setCategories] = useState<any | null>([]);
    const [commercials, setCommercials] = useState<any | null>([]);
    const [tempRemoveImages, setTempRemoveImages] = useState<any>([]);
    const [imagesLastIndexSave, setImagesLastIndexSave] = useState<any>(0);

    const [types] = useState<any>([
        {
            id: 'FACTURA',
            name: t('common.label.invoice')
        },
        {
            id: 'TICKET',
            name: t('common.label.ticket')
        },
        {
            id: 'FACTURA_TICKET',
            name: t('common.label.invoice-ticket')
        }
    ]);

    const [data, setData] = useState<any | null>({
        ...initialValues,

    });

    const handleSetImages = (values: any) => {
        const temp = Object.keys(values.imagen).map(
            (key) => values.imagen[key]
        );

        setImages([...images, ...temp]);
    };

    const handleDeleteImage = (index: number) => {
        const temp = [...images];

        if (temp[index]?.id) {
            api(`/imagenes/${temp[index].id}/`, 'DELETE');
        }

        temp.splice(index, 1);

        setImages([...temp]);
    };

    const handleOpenImage = (src: string) => {
        open(
            'custom',
            {
                title: t('common.image')
            },
            <BillImageViewer src={src}/>
        );
    };

    const onSubmit = (values: any | null) => {

        const limite = categories.find((category: any) => category.id === values.categoria)?.limite;

        if (!limite || (values.importe ?? 0 + values.importe_1 ?? 0 + values.importe_2 ?? 0 + values.importe_3 ?? 0 + values.importe_4 ?? 0) <= +limite) {

            let data;

            setIsSubmitting(true);

            values.importe = values.importe ? +values.importe.replace(",", ".") : 0;
            values.iva = values.iva ? +values.iva.replace(",", ".") : 0;
            values.importe_1 = values.importe_1 ? +values.importe_1.replace(",", ".") : 0;
            values.importe_2 = values.importe_2 ? +values.importe_2.replace(",", ".") : 0;
            values.importe_3 = values.importe_3 ? +values.importe_3.replace(",", ".") : 0;
            values.importe_4 = values.importe_4 ? +values.importe_4.replace(",", ".") : 0;
            values.iva_1 = values.iva_1 ? +values.iva_1.replace(",", ".") : 0;
            values.iva_2 = values.iva_2 ? +values.iva_2.replace(",", ".") : 0;
            values.iva_3 = values.iva_3 ? +values.iva_3.replace(",", ".") : 0;
            values.iva_4 = values.iva_4 ? +values.iva_4.replace(",", ".") : 0;
            values.bonificacion = values.bonificacion ? +values.bonificacion.replace(",", ".") : 0;


            if (values.tipo !== 'TICKET') {
                data = {...values, proveedor: values.proveedor.codigo ? values.proveedor.codigo : values.proveedor};
            } else {
                data = {...values};
            }


            if (values.id) {
                api(`/gastos/${values.id}/`, 'PATCH', data);
            } else {
                api('/gastos/', 'POST', data);
            }

            if (images.length) {
                props.close();
            }
        } else {
            toast.error("El importe supera el límite aurorizado")
        }
    };

    useEffect(() => {
        if (props.item) {
            setData({
                ...data,
                ...props.item,
                comercial: props.isCommercial ?
                    commercials.find((item: any) => item.id === props.commercialId)?.id :
                    props.item?.comercial ? props.item.comercial.id : '',
            });

            if (props?.item?.imagenes) {
                setImages([...props.item.imagenes]);
                setImagesLastIndexSave(props.item.imagenes.length);
            }
        } else {
            setData({
                ...data,
                ...initialValues,
                comercial: props.isCommercial ?
                    commercials.find((item: any) => item.id === props.commercialId)?.id :
                    ''
            });
        }
    }, [props.item, commercials]);

    useEffect(() => {
        api('/comerciales/?limit=9999999999', 'GET');
    }, []);

    useEffect(() => {
        if (state.data) {
            if (state.path.includes('categorias')) {
                setCategories(state.data.results);
            } else if (state.path.includes('comerciales')) {
                api('/categorias/?limit=9999999999', 'GET');
                setCommercials(state.data.results);
            } else if (state.path.includes('gastos')) {
                if (data.id) {
                    console.log("CIERRA")
                    props.close();
                } else {
                    setData(state.data);
                }
            } else if (state.path.includes('imagenes')) {
                // props.setRefresh(Date.now().toString());
            }
        }

        if (state.error) {
            toast.error(state.error.detail);
        }
    }, [state]);

    useEffect(() => {
        if (images.length > imagesLastIndexSave) {
            setImagesLastIndexSave(images.length);
            [...images]
                .splice(imagesLastIndexSave, images.length)
                ?.forEach((file: any) => {
                    const formData = new FormData();

                    if (!file.id) {
                        formData.append('gasto', data.id);
                        formData.append('name', file.name);
                        formData.append('imagen', file, file.name);

                        api('/imagenes/', 'POST', formData, true);
                    }
                });
        }
    }, [images]);

    return (
        <div className="flex relative h-full">
            <div className={images.length ? 'w-[70%]' : 'w-full'}>


                <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                        ...data,
                    }}
                    enableReinitialize={true}
                    validationSchema={props.item?.tipo === "TICKET" ? validationsTICKET : validations}
                >
                    {({
                          values,
                          errors,
                          setFieldValue,
                          handleSubmit,
                          handleBlur
                      }) => {

                        return (
                            <form
                                onSubmit={handleSubmit}
                                className="relative h-full pb-[100px] overflow-hidden overflow-y-auto"
                                autoComplete="off"
                            >
                                {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                                {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}

                                <Select
                                    name="tipo"
                                    label={t('common.label.type')}
                                    placeholder="common.label.select-value"
                                    onBlurChange={handleBlur}
                                    labelKey="name"
                                    items={types}
                                    value={values.tipo}
                                    onChange={(value) => {
                                        setFieldValue('tipo', value.tipo);
                                        setData({...data, tipo: value.tipo});
                                    }}
                                    errors={errors}
                                    touched={isSubmitting}
                                />


                                <div className="flex space-x-[24px]">
                                    <div className="w-1/2">
                                        <div>
                                            <DatePicker
                                                value={values.fecha}
                                                max={dayjs().format('YYYY-MM-DD')}
                                                onBlurChange={handleBlur}
                                                name="fecha"
                                                label={t('common.label.date-ticket')}
                                                onChange={(value) => {
                                                    console.log("VALUE :: ", value)
                                                    setFieldValue(
                                                        'fecha',
                                                        value.fecha
                                                    )
                                                }
                                                }
                                            />
                                        </div>
                                        <FieldError errors={errors} touched={{}} field={"fecha"}/>
                                    </div>

                                    <div className="w-1/2">
                                        <Input
                                            type="text"
                                            onBlurChange={handleBlur}
                                            name="numero"
                                            value={values.numero}
                                            label="common.label.number"
                                            placeholder="common.placeholder.number"
                                            onChange={(value) =>
                                                setFieldValue(
                                                    'numero',
                                                    value.numero
                                                )
                                            }
                                            errors={errors}
                                            touched={isSubmitting}
                                        />
                                    </div>
                                </div>

                                {values.tipo === 'FACTURA' ? (
                                    <div
                                        className="bg-gray-100 rounded-lg py-[8px] px-[16px] italic text-sm mb-[16px] flex flex-col items-center">
                                        <div className={"font-bold"}>{t('bill-form-label-total')}</div>
                                        <div className={""}>{
                                            (
                                                (+values.importe || 0) + (+values.iva || 0) +
                                                (+values.importe_1 || 0) + (+values.iva_1 || 0) +
                                                (+values.importe_2 || 0) + (+values.iva_2 || 0) +
                                                (+values.importe_3 || 0) + (+values.iva_3 || 0) +
                                                (+values.importe_4 || 0) + (+values.iva_4 || 0) -
                                                (+values.bonificacion || 0)
                                            ).toFixed(2)
                                        }</div>
                                    </div>
                                ) : null}

                                {
                                    (values.tipo === 'TICKET' ? [1] : [1, 2, 3, 4]).map((index: number) => (
                                        <div key={`base-${index}`}>
                                            {/*{index === 2*/}
                                            {/*    ? <div key={`base-info`}*/}
                                            {/*           className="bg-gray-100 rounded-lg py-[8px] px-[16px] italic text-sm mb-[16px]"*/}
                                            {/*           dangerouslySetInnerHTML={{__html: t("bill-form-label-optional")}}>*/}
                                            {/*    </div>*/}
                                            {/*    : null*/}
                                            {/*}*/}
                                            <div
                                                className="flex space-x-[24px] my-0"
                                            >
                                                <div
                                                    className={base.length < 4 && base.length <= index ? 'w-2/5' : 'w-1/2'}>
                                                    <Input
                                                        type="currency"
                                                        name={index !== 1 ? `importe_${index}` : 'importe'}
                                                        onBlurChange={handleBlur}
                                                        value={values[index !== 1 ? `importe_${index}` : 'importe']}
                                                        label="common.label.import"
                                                        extraLabel={index === 1
                                                            ? values.tipo !== 'TICKET' ? `21%` : ""
                                                            : index === 2
                                                                ? `10%`
                                                                : index === 3
                                                                    ? `4%`
                                                                    : `0%`
                                                        }
                                                        placeholder="common.placeholder.import"
                                                        onChange={(value) =>
                                                            setFieldValue(
                                                                index !== 1 ? `importe_${index}` : 'importe',
                                                                value[index !== 1 ? `importe_${index}` : 'importe']
                                                            )
                                                        }
                                                        errors={errors}
                                                        touched={isSubmitting}
                                                    />
                                                </div>

                                                {values.tipo !== 'TICKET' && (
                                                    <div
                                                        className={base.length < 4 && base.length <= index ? 'w-2/5' : 'w-1/2'}>
                                                        <div>
                                                            {index !== 4 &&
                                                                (<Input
                                                                    type="currency"
                                                                    name={index !== 1 ? `iva_${index}` : 'iva'}
                                                                    onBlurChange={handleBlur}
                                                                    value={values[index !== 1 ? `iva_${index}` : 'iva']}
                                                                    label="common.label.iva"
                                                                    extraLabel={index === 1
                                                                        ? values.tipo !== 'TICKET' ? `21%` : ""
                                                                        : index === 2
                                                                            ? `10%`
                                                                            : index === 3
                                                                                ? `4%`
                                                                                : `0%`
                                                                    }
                                                                    placeholder="common.placeholder.iva"
                                                                    onChange={(value) =>
                                                                        setFieldValue(index !== 1 ? `iva_${index}` : 'iva',
                                                                            value[index !== 1 ? `iva_${index}` : 'iva'])
                                                                    }
                                                                    errors={errors}
                                                                    touched={isSubmitting}
                                                                />)
                                                            }
                                                            <div className={"text-xs text-gray-400 -mt-6 mb-2"}>
                                                                Calculado: {index === 1
                                                                ? values["importe"] ? (+values["importe"] * 0.21).toFixed(2) : '-'
                                                                : index === 2
                                                                    ? values["importe_2"] ? (+values["importe_2"] * 0.10).toFixed(2) : '-'
                                                                    : index === 3
                                                                        ? values["importe_3"] ? (+values["importe_3"] * 0.04).toFixed(2) : '-'
                                                                        : '0.00'}

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                }

                                {values.tipo !== 'TICKET' && (
                                    <Input
                                        type="text"
                                        name="bonificacion"
                                        onBlurChange={handleBlur}
                                        value={values.bonificacion}
                                        label="common.label.bonification"
                                        placeholder="common.placeholder.bonification"
                                        onChange={(value) =>
                                            setFieldValue(
                                                'bonificacion',
                                                value.bonificacion
                                            )
                                        }
                                        errors={errors}
                                        touched={isSubmitting}
                                    />
                                )}

                                {
                                    (!props.isCommercial) && (
                                        <Select
                                            name="comercial"
                                            label={t('common.label.commercial')}
                                            placeholder="common.label.select-value"
                                            items={commercials}
                                            labelKey="email"
                                            disabled={data.id}
                                            onBlurChange={handleBlur}
                                            value={values.comercial}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'comercial',
                                                    value.comercial
                                                );
                                            }}
                                            errors={errors}
                                            touched={isSubmitting}
                                        />
                                    )
                                }

                                {values.tipo !== 'TICKET' && (
                                    <div>
                                        <SelectAsync
                                            name="proveedor"
                                            label={t('common.label.provider')}
                                            placeholder={t('common.label.write-to-search')}
                                            items={(value: string) => {
                                                return options(
                                                    `/proveedores/?limit=9999&name=${value}`
                                                );
                                            }}
                                            attributeId="codigo"
                                            extraOptionsLabel="ciudad"
                                            value={values.proveedor}
                                            onChange={(value: any) => {
                                                setFieldValue('proveedor', value);
                                            }}
                                            errors={errors}
                                            touched={isSubmitting}
                                        />
                                        {!values.proveedor ? (
                                                <div
                                                    className={`w-full text-red-600 font-regular text-[14px] text-right mt-[-21px]`}
                                                    id="feedback"
                                                >
                                                    {t('common.error.required')}
                                                </div>
                                            )
                                            : null}
                                    </div>
                                )}

                                <Select
                                    name="categoria"
                                    label={t('common.label.category')}
                                    placeholder="common.label.select-value"
                                    items={categories}
                                    onBlurChange={handleBlur}
                                    value={values.categoria}
                                    onChange={(value) => {
                                        setFieldValue(
                                            'categoria',
                                            value.categoria
                                        );
                                    }}
                                    errors={errors}
                                    touched={isSubmitting}
                                />

                                {values.categoria && categories.find((category: any) => category.id === values.categoria)?.limite
                                    ? <div className={"text-[11px] text-gray-400 capitalize"}>
                                        {t("common.label.limite")}:
                                        {categories.find((category: any) => category.id === values.categoria)?.limite}
                                    </div>
                                    : null
                                }

                                {
                                    (!props.isCommercial) && (
                                        // eslint-disable-next-line react/jsx-no-undef
                                        <Toggle
                                            label={t('clientes.form-label.exportado')}
                                            hasInfo
                                            value={values.exportado}
                                            onChange={(value: any) =>
                                                setFieldValue(
                                                    'exportado',
                                                    value.toggle
                                                )
                                            }
                                        />)
                                }

                                {values.id && (
                                    <>
                                        <div className={"my-8"}>
                                            Los datos del gasto ya estan guardados.
                                            <br/>
                                            <strong>Recordar subir la imagen del documento para poder dar validez a la entrada
                                                del gasto.</strong>
                                            <br/>
                                        </div>
                                        <FileInputElement
                                            label={t('common.label.images')}
                                            name="imagen"
                                            accept={"'image/png, image/jpeg','application/pdf"}
                                            multiple
                                            onChange={(values: any) => {
                                                handleSetImages(values);
                                            }}
                                        />
                                    </>
                                )}

                                <FormFooter
                                    isAbsolute={false}
                                    item={props.item}
                                    close={props.cancel}
                                    doDelete={props.doDelete}
                                    doSubmit={() => {
                                        setIsSubmitting(true);
                                        handleSubmit(values);
                                    }}
                                />
                            </form>
                        );
                    }}
                </Formik>
            </div>
            <div
                className={classNames(
                    'ml-5 flex flex-col space-y-4 px-2 h-[80vh] pb-[100px] overflow-hidden overflow-y-auto',
                    images.length ? 'w-[30%]' : 'hidden'
                )}
            >
                {images?.map((image: any, index: number) => (
                    <div key={index} className="relative">
                        <div
                            className={classNames(
                                'absolute w-full h-full bg-gray-200 hover:opacity-50 flex items-center justify-center',
                                tempRemoveImages.includes(image.id)
                                    ? 'opacity-50'
                                    : 'opacity-0'
                            )}
                        >
                            {tempRemoveImages.includes(image.id) ? (
                                <div className="flex space-x-4">
                                    <TrashIcon
                                        onClick={() => {
                                            handleDeleteImage(index);
                                        }}
                                        className="cursor-pointer text-white w-[50px] h-[50px] bg-gray-600 rounded-full p-2"
                                    />
                                    <EyeIcon
                                        onClick={() => {
                                            handleOpenImage(
                                                image.imagen ||
                                                URL.createObjectURL(image)
                                            );
                                        }}
                                        className="cursor-pointer text-white w-[50px] h-[50px] bg-gray-600 rounded-full p-2"
                                    />
                                </div>
                            ) : (
                                <CogIcon
                                    onClick={() => {
                                        clearTimeout(showTimeout);

                                        setTempRemoveImages([image.id]);

                                        setShowTimeout(
                                            setTimeout(() => {
                                                setTempRemoveImages([]);
                                            }, 2000)
                                        );
                                    }}
                                    className="cursor-pointer text-white w-[50px] h-[50px] bg-gray-600 rounded-full p-2"
                                />
                            )}
                        </div>
                        <img
                            src={image.imagen || URL.createObjectURL(image)}
                            alt=""
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
