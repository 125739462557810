import { useState, useEffect } from 'react';

import { noop } from '../../utils';
import { classNames } from '../../../utils/index';

export const DatePicker = ({
    min,
    max,
    name,
    label,
    value,
    disabled,
    type = 'date',
    onBlurChange,
    onChange = noop
}: {
    name: string;
    min?: string;
    max?: string;
    label: string;
    value?: string;
    disabled?: boolean;
    onBlurChange?: any;
    type?: 'date' | 'time';
    onChange: (item: { [key: string]: string | number }) => void;
}) => {
    const [defaultMin, setDefaultMin] = useState<string>();
    const [defaultMax, setDefaultMax] = useState<string>();
    const [defaultValue, setDefaultValue] = useState<string>();

    const handleChange = (e: { target: { value: string } }) => {
        onChange({ [name]: e.target.value });
    };

    useEffect(() => {
        if (value) {
            if (type === 'date') {
                const temp = new Date(value);

                setDefaultValue(temp.toISOString().split('T')[0]);
            } else {
                let temp: Date | string = new Date(value);

                setDefaultValue(
                    temp.toISOString().split('T').join(' ').split('.')[0]
                );
            }
        } else {
            setDefaultValue('');
        }
    }, [value]);

    useEffect(() => {
        if (type === 'date') {
            if (min) {
                const temp = new Date(min);

                setDefaultMin(temp.toISOString().split('T')[0]);
            }

            if (max) {
                const temp = new Date(max);

                setDefaultMax(temp.toISOString().split('T')[0]);
            }
        } else {
            if (min) {
                let temp: Date | string = new Date(min);

                setDefaultMin(
                    temp.toISOString().split('T').join(' ').split('.')[0]
                );
            }

            if (max) {
                let temp: Date | string = new Date(max);

                setDefaultMax(
                    temp.toISOString().split('T').join(' ').split('.')[0]
                );
            }
        }
    }, [min, max]);

    return (
        <div className="relative mb-[24px]">
            <label className="block text-sm font-normal leading-6 text-black mb-1">
                {label}
            </label>
            <input
                min={defaultMin}
                max={defaultMax}
                disabled={disabled}
                onChange={handleChange}
                onBlur={onBlurChange}
                defaultValue={defaultValue}
                type={type !== 'time' ? 'date' : 'datetime-local'}
                className={classNames(
                    'w-full font-light h-[42.5px] border-gray-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent outline-0',
                    disabled
                        ? 'text-gray-darker bg-gray-light'
                        : 'text-gray-darker bg-white'
                )}
            />
        </div>
    );
};
